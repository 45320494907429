.image {
  filter: blur(0px);
  transition: all;
  transition-duration: 200ms;
}

.categoryButtons {
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px 40px;
  border-radius: 3px;
  opacity: 0.8;
  font-weight: 600;
  font-size: 13pt;
  border: 2px solid #421234;
  transition: all;
  transition-duration: 200ms;
  //   font-family: "Afterglow";
  //   letter-spacing: 1.5px;
}

.categoryButtons:hover {
  background-color: #421234;
  color: white;
  border: 2px solid white;
  opacity: 1;
  font-size: 12.5pt;
}

.imageHolder {
  position: relative;
  transition: all;
  transition-delay: 200ms;
  height: 800px;
  overflow: hidden;
}

.image:hover {
  transform: scale(1.02);
}

.fadeOut {
  animation-name: fadeAway;
  animation-duration: 1s;
}

@keyframes fadeAway {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.Collections {
  &__Video {
    width: 80vw;
    position: relative;
    height: 45vw; /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */
    margin: 200px auto 50px auto;
    padding: 10px;
    border-radius: 4px;
    border: 2px solid rgba(128, 128, 128, 0.541);

    // & > iframe {
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    // }
  }
}
