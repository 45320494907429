.GetInTouch__Button {
  margin: 40px;
  background: white;
  font-weight: bold;
  width: fit-content;
  margin: 50px auto 0 auto;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}
.KnowMoreButton {
  margin: 80px auto 0 auto;
  font-size: 14pt;
  font-weight: bold;
  cursor: pointer;
  width: fit-content;
  border: 1px solid white;
  padding: 10px;
  border-radius: 4px;
}
