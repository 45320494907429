.About {
  &__Video {
    width: 80vw;
    position: relative;
    height:45vw; /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */
    margin: 0 auto;
    padding: 10px;
    border-radius: 4px;
    border: 2px solid rgba(128, 128, 128, 0.541);

    // & > iframe {
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    // }
  }
}

.Gallery__Container {
  width: 60vw;
  margin: 100px auto;
}

.Sedex {
  display: flex;
  align-items: center;
  font-size: 15pt;
  font-weight: bold;
  color: rgb(0, 0, 0);
  border: 1px solid white;
  width: fit-content;
  margin: 0 auto;
  border-radius: 5px;
  padding: 0 20px 0 0;
}
