.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
  background-image: url(./Resources/images/background.jpg);
  background-size: cover;
  overflow-y: scroll;
}

.Header {
  position: fixed;
  width: 100vw;
  top: 0;
  z-index: 500;
}

.Content {
  margin-top: 240px;
}

@import "react-slideshow-image/dist/styles.css";
