body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  margin: 0;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

@font-face {
  font-family: "Afterglow";
  src: url(./Resources//fonts/afterglow.ttf);
}
