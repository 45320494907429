.TextBox {
  color: #100d07;
  box-sizing: border-box;
  width: 80%;
  height: fit-content;
  background-color: #c4bfc4ab;
  background-color: rgba(255, 255, 255, 0.692);
  text-align: center;
  margin: 100px auto 0 auto;
  padding: 35px 10px 25px 10px;
  border-left: 2px solid #531741;
  border-right: 2px solid #531741;

  &__Heading {
    font-family: "Afterglow";
    font-size: 40pt;
    margin-bottom: 45px;

    &::first-letter {
      color: #661d50;
      // border-bottom: 2px solid #531741;
    }
  }

  &__Body {
    font-size: 14pt;
    font-weight: 450;
    line-height: 22pt;
  }
}

@media screen and (max-width: 900px) {
  .TextBox {
    margin-top: 50px;
    width: 90%;
  }
}

@media screen and (max-width: 600px) {
  .TextBox {
    margin-top: 380px;
    width: 98vw;

    &__Heading {
      font-family: "Afterglow";
      font-size: 25pt;
      margin-bottom: 35px;
    }

    &__Body {
      font-size: 12pt;
      font-weight: 450;
      line-height: 22pt;
    }
  }
}

@media screen and (max-width: 500px) {
  .TextBox {
    margin-top: 390px;
  }
}
