.Stats {
  display: flex;
  width: 80%;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-evenly;
  margin-top: 100px;

  &__Card {
    &--text {
      margin-top: 20px;

      font-size: 13pt;
      font-weight: 600;
      width: 200px;
    }
  }
}

@media screen and (max-width: 900px) {
  .Stats {
    margin-top: 40px;
    width: 90vw;
    // border: 1px solid white;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 15px;

    &__Card {
      &--text {
        // border: 1px solid white;
        font-size: 10pt;
        width: 70%;
        margin: 10px auto 0 auto;
      }
      & > img {
        height: 50px;
        width: 50px;
      }
    }
  }
}
