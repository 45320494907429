.Services__Content {
  margin-top: 170px;

  &__Service {
    height: 400px;
    width: 700px;
    border: 4px solid white;
    margin: 0 auto 20px auto;
    overflow: hidden;
    display: flex;
    width: 90%;
    z-index: 2;
    transition: all;
    transition-duration: 600ms;

    &:hover {
      border: 4px solid #9b2b79;
    }

    &--text {
      margin: 50px auto;
    }

    &--heading {
      width: fit-content;
      font-weight: bold;
      font-size: 25pt;
      border-bottom: 2px solid rgb(61, 1, 61);
      padding-bottom: 10px;
      margin: 0 auto;
    }

    &--subheading {
      font-size: 13pt;
      line-height: 130%;
      margin-top: 20px;
      width: 90%;
      margin: 40px auto;
    }
  }
}

.image {
  z-index: 1;
  transition: all;
  transition-duration: 2s;
}
.image :hover {
  transform: scale(1.05);
}
