.HomeSlideshow {
  margin-top: 50px;
  height: fit-content;
  width: 900px;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid grey;
  border-radius: 5px;
  padding: 10px;
}

.each-slide > div {
  height: 400px;
}

.react-slideshow-container .default-nav {
  transform: scale(0.6);
}
