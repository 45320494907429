.TopBar {
  background-color: #2b2925;
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  color: #d8d6d6;
  font-weight: 700;
  font-size: 10pt;
  letter-spacing: 1px;
  border-bottom: 3px solid #9b2b79;

  &__Left {
    display: flex;
    align-items: center;

    &--item {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-right: 30px;

      & img {
        margin-right: 5px;
      }
    }
  }

  &__Right {
    display: flex;
    align-items: center;

    & img {
      margin-left: 15px;
      height: 18px;
      width: 18px;
    }
  }
}

@media only screen and (max-width: 900px) {
  .TopBar {
    padding: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .TopBar {
    font-size: 8pt;

    &__Left {
      width: 100%;
      justify-content: space-between;

      &--item {
        margin-right: 0;
      }
    }

    &__Right {
      display: none;
    }
  }
}

// @media only screen and (max-width: 900px) {
//   .TopBar {
//     color: red;
//   }
// }
