.Nav {
  // background-color: #cacacaa2;
  // background-color: #cacacaf1;
  background-color: rgba(255, 255, 255, 0.918);
  // background-color: #f8f8ff;
  border-bottom: 2px solid #000000;
  color: #100d07;
  padding: 5px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Afterglow";
  font-size: 18pt;
  font-weight: 700;
  letter-spacing: 2px;

  &::first-letter {
    color: #9b2b79;
  }

  &__Left {
    width: 20%;
    padding: 10px 20px;

    &--logo {
      height: 100px;
    }
  }

  &__Right {
    width: 75%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    &--item {
      box-sizing: border-box;
      border-bottom: 3px solid rgba(0, 0, 0, 0);
      margin: 5px;
      padding: 5px 0;
      transition-property: all;
      transition-duration: 300ms;
      &:hover {
        cursor: pointer;
        border-bottom: 3px solid black;
      }

      &::first-letter {
        color: #661d50;
        font-size: 20pt;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .Nav {
    font-size: 16pt;
    flex-direction: column;

    &__Left {
      width: 100%;
      margin: 10px 0;
    }

    &__Right {
      width: 100%;
      justify-content: space-around;

      &--item {
        &::first-letter {
          font-size: 18pt;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .Nav {
    font-size: 12pt;
    &__Right {
      flex-direction: column;

      &--item {
        &::first-letter {
          font-size: 12pt;
        }
      }
    }
  }
}
